import React from 'react';
import Header1 from './Header1';
import './Services.css';
import { Link } from 'react-router-dom';

function Services() {
  const services = [
    {
      title: 'Custom Software Development',
      description: 'We build tailored and scalable software solutions to streamline your business operations.',
      link: '/CustomSoftware',
    },
    {
      title: 'IT Infrastructure Design & Support',
      description: 'Reliable IT systems designed and supported for optimal business performance.',
      link: '/Itinfra',
    },
    {
      title: 'Data Analytics and Management',
      description: 'Efficiently organize and analyze data to turn it into actionable insights.',
      link: '/DataAnalysis',
    },
    {
      title: 'Cloud Transformation Services',
      description: 'Secure, scalable cloud solutions for seamless business operations.',
      link: '/CloudTransform',
    },
    {
      title: 'Backup and Disaster Recovery Solutions',
      description: 'Protect your business with reliable backup and recovery solutions.',
      link: '/Backup',
    },
    {
      title: 'E-commerce and Web Application Development',
      description: 'Custom web platforms to enhance your digital presence and user experience.',
      link: '/E-commerce',
    },
    {
      title: 'OMR Service',
      description: 'Optical Mark Recognition service to automate form reading and result processing efficiently.',
      link: '/OmrService',
    },
  ];

  return (
    <div id="services" className="serviceBg">
      {/* Header Section */}
      <Header1 />
      <br />

      {/* Blue Panel Section */}
      <div className="bluePanel">
        <div className="heading">Our Services</div>
      </div>

      {/* Cards Section */}
      <div className="modules">
        {services.map((service, index) => (
          <div key={index} className="container">
            <div className="title">{service.title}</div>
            <div className="title-body">{service.description}</div>
            <div className="learnMore">
              <br />
              <Link to={service.link}>
                <button className="btnLearn">+ Learn More</button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;