import React from "react";
import Header1 from "./Header1";
import "./Services.css";

const OmrService = () => {
  return (
    <div className="custom-software-page">
      {/* Header Section */}
      <Header1 />
      <br />

      {/* Blue Panel Section */}
      <div className="bluePanel">
        <div className="heading">OMR Services</div>
      </div>

      {/* Content Section */}
      <main className="content">
        <p className="intro">
          <h4>
            <strong className="highlight">Accurate and Efficient OMR Solutions</strong>
          </h4>
          <br />
          Our Optical Mark Recognition (OMR) services streamline data collection and processing, providing precise solutions for examinations, surveys, and digitization.
        </p>

        {/* OMR Sheet Printing */}
        <section className="section">
          <strong className="highlight">OMR Sheet Printing:</strong>
          <ul>
            <li>
              High-quality, customized OMR sheets for exams, surveys, and forms.
            </li>
            <li>
              Design and layout tailored to specific requirements for better accuracy.
            </li>
          </ul>
        </section>

        {/* Data Collection */}
        <section className="section">
          <strong className="highlight">Data Collection and Analysis:</strong>
          <ul>
            <li>
              Efficient data collection using OMR sheets for market surveys, research, and examinations.
            </li>
            <li>
              Processing large volumes of data quickly and accurately.
            </li>
          </ul>
        </section>

        {/* Scanning and Digitization */}
        <section className="section">
          <strong className="highlight">OMR Scanning and Document Digitization:</strong>
          <ul>
            <li>
              High-speed OMR sheet scanning for accurate mark detection.
            </li>
            <li>
              Document digitization to convert paper documents into organized digital formats.
            </li>
          </ul>
        </section>

        {/* Examination Solutions */}
        <section className="section">
          <strong className="highlight">Examination Solutions:</strong>
          <ul>
            <li>
              Pre-exam, exam, and post-exam OMR processing to simplify result generation.
            </li>
            <li>
              Custom solutions for educational institutions and examination boards.
            </li>
          </ul>
        </section>

        {/* Why Choose Us */}
        <section className="section">
          <strong className="highlight">Why Choose Our OMR Services?</strong>
          <ul>
            <li>
              Accuracy and reliability for surveys, tests, and data collection.
            </li>
            <li>
              End-to-end services including printing, scanning, and processing.
            </li>
            <li>
              Fast turnaround time to meet tight deadlines for result processing.
            </li>
          </ul>
        </section>

        {/* Back Link */}
        <a href="/Services" className="backButton">
          Back
        </a>
      </main>
    </div>
  );
};

export default OmrService;