import './App.css';
import Home from './components/Home';
import Services from './components/Services';
import Clients from './components/Clients';
import Footer from './components/Footer';
import Header1 from './components/Header1';
import FindOutMore from './components/FindOutMore';
import CustomSoftwareDevelopment from './components/CustomSoftware';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ITInfrastructure from './components/ITinfra';
import DataAnalytics from './components/DataAnalysis';
import CloudTransformation from './components/CloudTransform';
import BackupAndDisasterRecovery from './components/Backup';
import Ecommerce from './components/E-commerce';
import OmrService from './components/OmrService';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/header" element={<Header1 />} />
        <Route path="/services" element={<Services />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="/CustomSoftware" element={<CustomSoftwareDevelopment />} />
        <Route path="/ITinfra" element={<ITInfrastructure />} />
        <Route path="/DataAnalysis" element={<DataAnalytics />} />
        <Route path="/CloudTransform" element={<CloudTransformation />} />
        <Route path="/Backup" element={<BackupAndDisasterRecovery />} />
        <Route path="/E-commerce" element={<Ecommerce />} />
        <Route path="/OmrService" element={<OmrService />} /> {/* Fixed this */}
        <Route path="/findOutMore" element={<FindOutMore />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;